import styled from 'styled-components';
import { FiLoader } from 'react-icons/fi';

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LoaderIcon = styled(FiLoader)<{ big?: boolean }>`
  position: relative;
  animation: spin 2s linear infinite;

  @keyframes spin {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${({ big }) => (big ? ` width: 50px; height: 50px;` : ``)}
`;
