import { ModalData } from 'module/Modal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CELL_CATEGORIES } from 'module/Planning/utils';
import { People } from 'module/People';
import { Team } from 'module/Teams';

export type CellCoordinates = { rowIndex: number; columnIndex: number; todaysRowIndex?: number };

export type CellData = {
  category: CELL_CATEGORIES;
  value: string;
};

export type PeopleCellData = {
  value?: People | Team | string;
  color?: string;
};

export type PlanningCellData = CellData & {
  modalData: ModalData | null;
};

export type GridState = {
  data: {
    datesData: CellData[][];
    peopleData: PeopleCellData[][];
    planningData: PlanningCellData[][];
  };
};

export type GridAction = PayloadAction<GridState>;

const initialState: GridState = {
  data: { datesData: [], peopleData: [], planningData: [] },
};

const GridSlice = createSlice({
  name: 'Grid',
  initialState,
  reducers: {
    updateGridData: (state, action: PayloadAction<GridState['data']>) => {
      state.data = action.payload;
    },
  },
});

export const { updateGridData } = GridSlice.actions;

export default GridSlice.reducer;
