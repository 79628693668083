import { RootState } from 'redux/types';
import { CELL_CATEGORIES } from 'module/Planning/utils';
import { CellData, PlanningCellData } from '.';

const cellDataNotFound = { value: 'not found', category: CELL_CATEGORIES.EMPTY };
const planningCellDataNotFound = { ...cellDataNotFound, modalData: null };

export const getPlanningGrid = (store: RootState) => store.grid.data.planningData;
export const getPeopleGrid = (store: RootState) => store.grid.data.peopleData;
export const getDatesGrid = (store: RootState) => store.grid.data.datesData;

export const getComputedPeopleCellData =
  (rowIndex: number, columnIndex: number) =>
  (store: RootState): any => {
    const peopleGrid = getPeopleGrid(store);
    return peopleGrid && peopleGrid[rowIndex] && peopleGrid[rowIndex][columnIndex]
      ? peopleGrid[rowIndex][columnIndex]
      : cellDataNotFound;
  };

export const getComputedDateCellData =
  (rowIndex: number, columnIndex: number) =>
  (store: RootState): CellData => {
    const datesGrid = getDatesGrid(store);
    return datesGrid && datesGrid[rowIndex] && datesGrid[rowIndex][columnIndex]
      ? datesGrid[rowIndex][columnIndex]
      : cellDataNotFound;
  };

export const getComputedPlanningCellData =
  (rowIndex: number, columnIndex: number) =>
  (store: RootState): PlanningCellData => {
    const PlanningGrid = getPlanningGrid(store);
    return PlanningGrid && PlanningGrid[rowIndex] && PlanningGrid[rowIndex][columnIndex]
      ? PlanningGrid[rowIndex][columnIndex]
      : planningCellDataNotFound;
  };
