import React from 'react';
import { LoaderIcon, LoaderWrapper } from './Loader.style';

const Loader = ({ big }: { big?: boolean }) => (
  <LoaderWrapper>
    <LoaderIcon big={big} />
  </LoaderWrapper>
);

export default Loader;
