import { useDispatch } from 'react-redux';
import { People } from 'module/People';
import { Team } from 'module/Teams';
import moment, { Moment } from 'moment';
import { Planning } from 'module/Planning';
import {
  CELL_CATEGORIES,
  createNewPlanning,
  getPlanningCategory,
  groupPlanningByDateAndMember,
} from 'module/Planning/utils';
import { Dictionary } from '@reduxjs/toolkit';
import { PeopleCellData, PlanningCellData, updateGridData } from './slice';

const FREELANCE_TEAM_LABEL = 'Freelance';

export const useUpdateGridDate = () => {
  const dispatch = useDispatch();

  return (
    dates: string[] = [],
    people: Dictionary<People>,
    teams: Dictionary<Team>,
    plannings: Planning[],
  ) => {
    const planningByDateAndMember = groupPlanningByDateAndMember(plannings);

    const peopleIds = Object.keys(people);

    const generateDateLine = (date: Moment) => {
      const now = moment();
      const isDateInThePast = date.isBefore(now, 'day');
      const category = isDateInThePast
        ? CELL_CATEGORIES.PAST
        : date.isSame(now, 'day')
        ? CELL_CATEGORIES.TODAY
        : CELL_CATEGORIES.FUTURE;
      return [
        {
          value: `${date.format('ddd')}\n${date.format('DD')}`,
          category,
        },
      ];
    };

    const datesData = dates.map(date => generateDateLine(moment(date)));

    const peopleArray = peopleIds.reduce<PeopleCellData[][]>(
      (peopleDataArray, peopleId) => {
        const peopleData = people[peopleId];
        if (peopleData) {
          const team = teams[peopleData.teams[0]];
          peopleDataArray[0].push({
            value:
              peopleData.firstName +
              ' ' +
              (team?.name === FREELANCE_TEAM_LABEL ? peopleData.lastName : peopleData.lastName[0]),
            color: peopleData.subTeamColor,
          });
          peopleDataArray[1].push({
            value: team?.name ? team.name : 'error for team retrieval',
            color: peopleData.subTeamColor,
          });
        } else {
          peopleDataArray[0].push({
            value: 'error on cubik name retrieval',
          });
          peopleDataArray[1].push({
            value: 'error for team retrieval',
          });
        }
        return peopleDataArray;
      },
      [[], []],
    );
    const planningData = dates.map<PlanningCellData[]>((dateString: string) => {
      return peopleIds.map<PlanningCellData>((columnsData: string) => {
        const date = moment(dateString);
        const peopleData = people[columnsData];
        if (!peopleData || !peopleData.id) {
          return { value: '', category: CELL_CATEGORIES.EMPTY, modalData: null };
        }
        const isAM = date.get('hour') < 12;
        const planningsOnThisDate =
          planningByDateAndMember[`${date.format('YYYY-MM-DD')}-${isAM ? 'AM' : 'PM'}`];

        const cellPlanning = !!planningsOnThisDate && planningsOnThisDate[peopleData.id];

        const planningsOnThisDatePM = planningByDateAndMember[`${date.format('YYYY-MM-DD')}-PM`];
        const cellPlanningPM = !!planningsOnThisDatePM && planningsOnThisDatePM[peopleData.id];

        return {
          value: cellPlanning && cellPlanning.projectName ? cellPlanning.projectName : '',
          modalData: {
            planning: cellPlanning
              ? cellPlanning
              : createNewPlanning(peopleData.id, date.toDate(), !!cellPlanningPM),
            peopleData,
            date: date.toDate(),
          },
          category: cellPlanning ? getPlanningCategory(cellPlanning) : CELL_CATEGORIES.EMPTY,
        };
      });
    });

    return dispatch(updateGridData({ datesData, peopleData: peopleArray, planningData }));
  };
};

export default { useUpdateGridDate };
